import { graphql } from "gatsby";

import { Head } from "../components/Head";
import { Layout } from "../components/Layout";

import { MarkdownRenderer } from "../common/Markdown";
import {
  SectionContainer,
  SectionWrapper
} from "../common/Section";

import { LegalHero } from "../components/Hero";


interface Props {
  data: any
};


const LegalTemplate = ({
  data
}: Props ) => {
  const socials = data?.allPrismicSocial?.edges || [];

  const global = data?.prismicSiteInfo?.data || {};

  const legal = data?.prismicLegal?.data || {};

  return (
    <Layout
      global={ global }
      socials={ socials }
    >
      <Head
        title={ legal.title.text }
        description={ legal.description.text }
      />

      <SectionWrapper>
        <LegalHero
          heading={ legal.hero_heading.text }
          lastUpdated={ legal.last_updated }
        />
      </SectionWrapper>

      <SectionWrapper>
        <SectionContainer>
          <MarkdownRenderer
            body={ legal.legal_body.html }
          />
        </SectionContainer>
      </SectionWrapper>
    </Layout>
  )
}


export const query = graphql`
  query Legal(
    $id: String!
  ) {
    allPrismicSocial {
      edges {
        node {
          _previewable
          data {
            platform
            link {
              url
            }
          }
          uid
        }
      }
    }

    prismicSiteInfo {
      _previewable
      data {
        phone_number {
          text
        }
        phone_number_link {
          url
        }

        email {
          text
        }
        email_link {
          url
        }

        address {
          text
        }

        accreditations {
          image {
            alt
            gatsbyImageData(
              height: 40
              placeholder: BLURRED
            )
          }
        }

        copyright {
          text
        }
      }
      uid
    }

    prismicLegal(
      id: {
        eq: $id
      }
    ) {
      _previewable
      data {
        title {
          text
        }
        description {
          text
        }

        hero_heading {
          text
        }
        last_updated(
          formatString: "MMMM DD, YYYY"
        )
        legal_body {
          html
        }
      }
      uid
    }
  }
`;


export default LegalTemplate;
